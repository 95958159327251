import React, { useState, useEffect } from "react";
import _ from "lodash";
import History from "../components/Steps/History";
import Step1 from "../components/Steps/Step1";
import Step2 from "../components/Steps/Step2";
import Step3 from "../components/Steps/Step3";
import Step4 from "../components/Steps/Step4";
import Step5 from "../components/Steps/Step5";
import Step6 from "../components/Steps/Step6";
import { Form, Spin } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import $axios from "../services/axios";
import showCustomNotification from "../components/Common/showCustomNotification";
import moment from "moment";

const Home = ({ token }) => {
  const [form] = Form.useForm();
  const [listFlight, setListFlight] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tripType, setTripType] = useState("oneWay");
  const [chooseFlight, setChooseFlight] = useState([]);
  const [airports, setAirports] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [airportInfo, setAirportInfo] = useState([]);
  const [airlineInfo, setAirlineInfo] = useState([]);
  const [isPromotionOrSameDayTicket, setIsPromotionOrSameDayTicket] =
    useState(false);
  const [arrival, setArrival] = useState([]);
  const [step, setStep] = useState(null);
  const [bookingInfo, setBookingInfo] = useState({});
  const [filterDay, setFilterDay] = useState("");
  const [filterDayReturn, setFilterDayReturn] = useState("");
  const [loadingSearchFlight, setLoadingSearchFlight] = useState(false);
  const [loadingAirPorts, setLoadingAirPorts] = useState(true);
  const [ChoosingStep, setChoosingStep] = useState(1);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    hanleProcessPaymentReturn();
  }, [location, history]);

  const handleBookingInfo = (booking) => {
    setBookingInfo(booking);
  };

  const hanleProcessPaymentReturn = async () => {
    const queryParams = new URLSearchParams(location.search);
    const amount = queryParams.get("amount");
    const payment_type = queryParams.get("payment_type");
    const reference_number = queryParams.get("reference_number");
    const status = queryParams.get("status");
    const trans_ref_no = queryParams.get("trans_ref_no");
    const website_id = queryParams.get("website_id");
    const signature = queryParams.get("signature");

    if (
      amount &&
      payment_type &&
      reference_number &&
      status &&
      trans_ref_no &&
      website_id &&
      signature
    ) {
      setStep(6);
    } else if (!step && token) {
      setStep(1);
    }
  };

  useEffect(() => {
    filterDay && fetchSearchFlight(form.getFieldValue());
  }, [filterDay, filterDayReturn]);

  const fetchSearchFlight = async (values) => {
    setLoadingSearchFlight(true);
    try {
      const response = await $axios.$post(`/flights/searchflight`, {
        Adt: values.Adt,
        Chd: values.Chd,
        Inf: values.Inf,
        ViewMode: "",
        ListFlight:
          tripType === "oneWay"
            ? [
                {
                  Airline: "",
                  StartPoint: values.StartPoint,
                  EndPoint: values.EndPoint,
                  DepartDate: filterDay,
                },
              ]
            : [
                {
                  Airline: "",
                  StartPoint: values.StartPoint,
                  EndPoint: values.EndPoint,
                  DepartDate: filterDay,
                },
                {
                  Airline: "",
                  StartPoint: values.EndPoint,
                  EndPoint: values.StartPoint,
                  DepartDate: filterDayReturn,
                },
              ],
      });
      setListFlight(
        response.data.data.ListFareData.map((item) => ({
          ...item,
          FlightType: response.data.data.FlightType,
          Session: response.data.data.Session,
        }))
      );
      setLoadingSearchFlight(false);
    } catch (error) {
      setListFlight([]);
      setLoadingSearchFlight(false);
    } finally {
      setLoading(false);
    }
  };

  const getAirPorts = async () => {
    try {
      setLoadingAirPorts(true);
      const response = await $axios.$get(`/flights/airports`);
      setAirports(response.data.data);
      setLoadingAirPorts(false);
    } catch (error) {
      showCustomNotification({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    }
  };

  const getAirLines = async () => {
    try {
      const response = await $axios.$get(`/flights/airlines`);
      setAirlines(response.data.data);
    } catch (error) {
      showCustomNotification({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    }
  };

  useEffect(() => {
    if (step && step === 1) {
      getAirPorts();
      getAirLines();
    }
  }, [step]);

  useEffect(() => {
    if (arrival.length > 0) {
      setAirportInfo(_.filter(airports, (air) => arrival.includes(air.code)));
    }
  }, [arrival, airports]);

  useEffect(() => {
    if (chooseFlight.length > 0) {
      const isSameDayTicket = _.some(chooseFlight, (f) =>
        moment(f.ListFlight[0].StartDate).isSame(moment(), "day")
      );
      let isPromo = true;
      _.forEach(chooseFlight, (f) => {
        // check vé khuyến mãi
        if (!f.Promo) {
          isPromo = false;
        }
      });
      setIsPromotionOrSameDayTicket(isPromo || isSameDayTicket);
      setAirlineInfo(
        _.filter(airlines, (air) =>
          _.some(chooseFlight, (f) => f.Airline === air.code)
        )
      );
    }
  }, [chooseFlight, airlines]);

  const handleBackButton = () => {
    setStep(1);
    setFilterDayReturn("");
    setFilterDay("");
  };

  return (
    <div className="home">
      <Spin
        tip={
          <div>
            <div className="text-[16px]">Yêu cầu đặt vé đang xử lý</div>
            <div className="text-[16px]">
              Xin quý khách vui lòng đợi trong giây lát...
            </div>
          </div>
        }
        size="large"
        spinning={loading}
      >
        {step === 0 && (
          <History
            airports={airports}
            onChangeStep={setStep}
            setLoading={setLoading}
          />
        )}
        {step === 1 && (
          <Step1
            onChangeStep={setStep}
            tripType={tripType}
            setTripType={setTripType}
            setAirport={setArrival}
            airport={arrival}
            airports={airports}
            chooseFlight={chooseFlight}
            setChooseFlight={setChooseFlight}
            setFilterDay={setFilterDay}
            setFilterDayReturn={setFilterDayReturn}
            loadingAirPorts={loadingAirPorts}
            form={form}
          />
        )}
        {step === 2 && (
          <Step2
            onChangeStep={setStep}
            listFilght={listFlight}
            chooseFlight={chooseFlight}
            setChooseFlight={setChooseFlight}
            tripType={tripType}
            airport={arrival}
            airports={airports}
            setStep={setStep}
            filterDay={filterDay}
            setFilterDay={setFilterDay}
            filterDayReturn={filterDayReturn}
            setFilterDayReturn={setFilterDayReturn}
            loadingSearchFlight={loadingSearchFlight}
            ChoosingStep={ChoosingStep}
            setChoosingStep={setChoosingStep}
            onBackButton={handleBackButton}
          />
        )}
        {step === 3 && (
          <Step3
            onChangeStep={setStep}
            isPromotionOrSameDayTicket={isPromotionOrSameDayTicket}
            tripType={tripType}
            airportInfo={airportInfo}
            chooseFlight={chooseFlight}
            setLoading={setLoading}
            onChangeBookingInfo={(e) => {
              handleBookingInfo(e);
              sessionStorage.setItem("bookingInfo", JSON.stringify(e));
            }}
            airports={airports}
            airlines={airlines}
            setStep={setStep}
          />
        )}
        {step === 4 && (
          <Step4
            airlineInfo={airlineInfo}
            airportInfo={airportInfo}
            onChangeStep={setStep}
            isPromotionOrSameDayTicket={isPromotionOrSameDayTicket}
            bookingInfo={bookingInfo}
            chooseFlight={chooseFlight}
          />
        )}
        {step === 5 && (
          <Step5
            airlineInfo={airlineInfo}
            airportInfo={airportInfo}
            setLoading={setLoading}
            isPromotionOrSameDayTicket={isPromotionOrSameDayTicket}
            bookingInfo={bookingInfo}
            chooseFlight={chooseFlight}
            airports={airports}
          />
        )}
        {step === 6 && (
          <Step6
            setLoading={setLoading}
            onChangeStep={setStep}
            loading={loading}
          />
        )}
      </Spin>
      {/* <div className="flex bg-white h-[81px] w-full px-[24px] py-3 fixed bottom-0 justify-between items-center">
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-may-bay.svg"} alt="" />
          <a
            href="/ve-may-bay"
            className="text-[#22313F] text-[14px] font-medium"
          >
            Vé máy bay
          </a>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/khach-san.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">
            Khách sạn
          </span>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-tau.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">
            Vé tàu
          </span>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-xe.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">
            Vé xe
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
